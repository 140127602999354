<template>
  <div>
    视频巡检记录
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
</style>
